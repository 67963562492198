import { axiosACN } from '@/js/axiosInstances.js'
import sha256 from 'crypto-js/sha256'
import { ENV_CONFIGS } from '@/js/configs.js'
import GenErr from '@/js/genericErrors'

import router from '../../router/router'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    username: null,
    user: null,
    token: null,
    refreshToken: null,
    expirationDate: null,
    privacy: false,
    passwordExpired: false,
    accountTypes: null,
    kaleyraData: '',
    accountDeletionRequested: false,
    isFeedbackShowing: false
  },
  getters: {
    username: (state) => state.username,
    token: (state) => state.token,
    user: (state) => state.user,
    isAuthenticated: (state) => !!state.token,
    privacy: (state) => state.privacy,
    passwordExpired: (state) => state.passwordExpired,
    accountTypes: (state) => state.accountTypes,
    kaleyraData: (state) => state.kaleyraData,
    getAccountDeletionRequested: (state) => state.accountDeletionRequested
  },
  mutations: {
    setFeedbackShowing(state, isFeedbackShowing) {
      state.isFeedbackShowing = isFeedbackShowing
    },
    authUser(state, authData) {
      state.token = authData.token
      state.expirationDate = authData.expirationDate
      state.username = authData.username
      state.passwordExpired = false
      state.accountTypes = authData.accountTypes
    },
    setExternalAuth(state, auth) {
      state.token = auth.token
      state.username = auth.username
    },
    storeUser(state, user) {
      state.user = user
    },
    setPrivacy(state, privacy) {
      state.privacy = privacy
    },
    clearAuthData(state) {
      state.username = null
      state.token = null
      state.refreshToken = null
      state.expirationDate = null
      state.accountTypes = null
    },
    passwordExpired(state, passwordExpired) {
      state.passwordExpired = passwordExpired
    },
    setKaleyraData(state, kaleyraData) {
      state.kaleyraData = kaleyraData
    },
    setAccountDeletionRequested(state, accountDeletionRequested) {
      state.accountDeletionRequested = accountDeletionRequested
    }
  },
  actions: {
    login: async ({ commit, dispatch }, authData) => {
      const shaPw = sha256(authData.password).toString()

      try {
        let res = await axiosACN.post(
          '/sorgenia/V4/login',
          {
            username: authData.username,
            password: shaPw,
            sourceChannel: 'MYS'
          },
          {
            headers: {
              Authorization: ENV_CONFIGS.BASIC_TOKEN
            }
          }
        )
        if (typeof dtrum !== 'undefined') {
          window.dtrum.identifyUser(authData.username)
        } else {
        }
        function createError(type, res) {
          const error = new Error(type)
          error.errorCode = res.data.errorCode
          error.errorDescription = res.data.errorDescription
          error.status = res.statusText
          error.statusCode = res.status
          return error
        }
        if (res.status && res.status >= 400) {
          if (res.status === 403 && res.data.errorCode === '1015') {
            throw createError('ACCOUNT_NOT_READY', res)
          } else if (res.status === 423) {
            throw createError('ACCOUNT_LOCKED', res)
          } else if (res.data.errorCode) {
            const errorCode = res.data.errorCode
            switch (errorCode) {
              case '001':
              case '010':
                throw createError('WRONG_USER_PASSWORD', res)
              case '009':
                throw createError('MAIL_NOT_CONFIRMED', res)
              default:
                throw createError('UNKNOWN_ERROR', res)
            }
          } else {
            throw createError('SERVER_ERROR', res)
          }
        } else {
          if (res.data.accountTypes?.includes('PROSPECT')) {
            res.data.prospect = true
            return res.data
          }
        }

        if (res.status === 200) {
          switch (res.data.errorCode) {
            case '002':
              commit('authUser', {
                username: authData.username,
                token: res.data.accessToken,
                accountTypes: res.data.accountTypes
              })
              commit('passwordExpired', true)
              throw createError('EXPIRED_PASSWORD', res)
            case '009':
              throw createError('MAIL_NOT_CONFIRMED', res)
          }
        }

        const { accessToken, refreshToken, expiresIn, accountTypes } = res.data
        localStorage.setItem('username', authData.username || '')
        localStorage.setItem('token', accessToken || '')
        localStorage.setItem('refreshToken', refreshToken || '')
        localStorage.setItem('expirationDate', expiresIn || '')
        localStorage.setItem('accountTypes', accountTypes || '')

        commit('authUser', {
          username: authData.username,
          token: accessToken,
          expirationDate: expiresIn,
          accountTypes: accountTypes
        })

        return res.data
      } catch (err) {
        console.error('Session error' + err)
        if (err.statusCode && err.statusCode >= 400) {
          if (err.statusCode === 423) {
            const error = new Error('ACCOUNT_LOCKED')
            error.errorCode = err.errorCode
            error.errorDescription = err.errorDescription
            error.status = err.status
            throw error
          } else {
            const error = new Error(err.message)
            error.errorCode = err.errorCode
            error.errorDescription = err.errorDescription
            error.status = err.status
            throw error
          }
        } else if (err.message) {
          const error = new Error(err.message)
          error.errorCode = err.errorCode
          error.errorDescription = err.errorDescription
          error.status = err.status
          throw error
        }
      }
    },

    kaleyraAuth: ({ getters, commit }, clientCode) => {
      return axiosACN
        .post(
          '/sorgenia/tokenKaleyra',
          {
            clientCode: clientCode,
            expiresIn: 86400
          },
          {
            headers: {
              Authorization: 'Bearer ' + getters.token
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw GenErr.showErrorMessage()
          }
          commit('setKaleyraData', res.data)
          return res.data
        })
        .catch(() => {
          throw GenErr.showErrorMessage()
        })
    },

    sendInfoKaleyra: ({ getters, rootGetters }, journey) => {
      return axiosACN
        .post(
          '/sorgeniadataclient/sendInfoKaleyra',
          {
            clientCode: rootGetters['account/mainClient'].clientOwnerCode,
            clientName:
              rootGetters['account/mainClient'].firstName ||
              rootGetters['account/mainClient'].businessName,
            clientSurname:
              rootGetters['account/mainClient'].lastName ||
              rootGetters['account/mainClient'].businessName,
            journey: journey
          },
          {
            headers: {
              Authorization: 'Bearer ' + getters.token
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw GenErr.showErrorMessage()
          }
          return res.data
        })
        .catch(() => {
          throw GenErr.showErrorMessage()
        })
    },

    adminLogin: async ({ commit, dispatch }, authData) => {
      let module = () =>
        import(/* webpackChunkName: "superlogin" */ '../../js/superlogin.js')
      const result = await (
        await module()
      ).superlogin({ commit, dispatch }, authData)
      return result
    },

    logout: ({ commit, dispatch }, options = {}) => {
      commit('clearAuthData')

      localStorage.removeItem('expirationDate')
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('accountTypes')

      if (typeof dtrum !== 'undefined') {
        window.dtrum.endSession()
      }

      commit('account/resetState', false, { root: true })
      commit('fiber/resetState', false, { root: true })
      commit('interactions/resetState', false, { root: true })
      commit('offers/resetState', false, { root: true })
      commit('payment/resetState', false, { root: true })
      commit('selfRegistration/resetState', false, { root: true })
      commit('supply/resetState', false, { root: true })
      commit('support/resetState', false, { root: true })
      commit('upsellingFiber/resetState', false, { root: true })
      commit('feedback/resetState', false, { root: true })
      commit('welcomekit/resetState', false, { root: true })
      commit('loyalty/resetState', false, { root: true })

      window.kaleyra.disconnect()

      if (!ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
        dispatch('analytics/setupTracking', false, { root: true })
      }

      let query = options.reason || options.forwardURL ? '?' : ''
      if (options.reason || options.forwardURL) {
        for (let p in options) {
          query += p + '=' + options[p] + '&'
        }
      }
      router.replace('/login' + query).then(() => location.reload()) // workaround, problem of state in Chatbot, for now full refresh to solve
    },

    accountDeleted: ({ commit, dispatch }, options = {}) => {
      commit('clearAuthData')

      localStorage.removeItem('expirationDate')
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('accountTypes')

      if (typeof dtrum !== 'undefined') {
        window.dtrum.endSession()
      }

      commit('account/resetState', false, { root: true })
      commit('fiber/resetState', false, { root: true })
      commit('interactions/resetState', false, { root: true })
      commit('offers/resetState', false, { root: true })
      commit('payment/resetState', false, { root: true })
      commit('selfRegistration/resetState', false, { root: true })
      commit('supply/resetState', false, { root: true })
      commit('support/resetState', false, { root: true })
      commit('upsellingFiber/resetState', false, { root: true })
      commit('feedback/resetState', false, { root: true })
      commit('welcomekit/resetState', false, { root: true })
      commit('loyalty/resetState', false, { root: true })

      window.kaleyra.disconnect()

      if (!ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
        dispatch('analytics/setupTracking', false, { root: true })
      }

      router.replace('/confirmAccountDeleted').then(() => location.reload()) // workaround, problem of state in Chatbot, for now full refresh to solve
    },
    refreshToken: async ({ commit, state }) => {
      try {
        const username = localStorage.getItem('username')
        const refreshToken = localStorage.getItem('refreshToken')

        if (refreshToken) {
          const res = await axiosACN.post(
            '/sorgenia/V5/refreshtoken',
            {
              username: username,
              sourceChannel: 'MYS'
            },
            {
              headers: {
                Authorization: ENV_CONFIGS.BASIC_TOKEN,
                refreshToken: refreshToken,
                'Ocp-Apim-Subscription-Key': ENV_CONFIGS.ACN_SUB_KEY
              }
            }
          )
          // se payload contiene errore 002 (password scaduta) o non presente expiresIn (anche se status 200)
          if ((res.error && res.error === '002') || !res.data.expiresIn) {
            throw new Error('NO_REFRESH_TOKEN')
          }
          const expirationDate = res.data.expiresIn
          localStorage.setItem('token', res.data.accessToken)
          localStorage.setItem('refreshToken', res.data.refreshToken)
          localStorage.setItem('expirationDate', expirationDate)
          localStorage.setItem('accountTypes', res.data.accountTypes)

          commit('authUser', {
            token: res.data.accessToken,
            username: res.data.username,
            expirationDate: expirationDate,
            accountTypes: res.data.accountTypes
          })

          return res.data.accessToken
        } else {
          throw new Error('NO_REFRESH_TOKEN')
        }
      } catch (error) {
        console.error(error)
        throw new Error('REFRESH_TOKEN_FAILED')
      }
    },

    tryAutoLogin: ({ commit }) => {
      const token = localStorage.getItem('token')
      if (!token) {
        return
      }
      const expirationDate = localStorage.getItem('expirationDate')
      const now = new Date()
      if (now >= expirationDate) {
        return
      }
      const username = localStorage.getItem('username')
      const accountTypes = localStorage.getItem('accountTypes')
      commit('authUser', {
        username: username,
        token: token,
        expirationDate: expirationDate,
        accountTypes: accountTypes
      })
    },

    changePassword: async ({ getters, commit, dispatch }, authData) => {
      const shaPw = sha256(authData.password).toString()

      try {
        let res = await axiosACN.post(
          '/sorgenia/V2/changepwd',
          {
            username: getters.username,
            new_password: shaPw
          },
          {
            headers: {
              Authorization: 'Bearer ' + getters.token,
              'Ocp-Apim-Subscription-Key': ENV_CONFIGS.ACN_SUB_KEY
              // 'access_token': getters.token,
              // 'token_type': 'bearer'
            }
          }
        )

        if (res.data.errorCode) {
          throw Error('UNKNOWN_ERROR')
        }

        const expirationDate = res.data.expires_in
        localStorage.setItem('username', getters.username || '')
        localStorage.setItem('token', res.data.access_token || '')
        localStorage.setItem('refreshToken', res.data.refresh_token || '')
        localStorage.setItem('expirationDate', expirationDate || '')

        commit('authUser', {
          username: getters.username,
          token: res.data.access_token,
          expirationDate: expirationDate,
          accountTypes: getters.accountTypes
        })
        const fromPath = router.history.current.path

        const fwdURL = router.currentRoute.query['forwardURL']
        if (fwdURL) {
          router.replace(fwdURL)
        } else if (
          fromPath === '/private/account' ||
          fromPath === '/private/profile/information'
        ) {
        } else {
          router.replace('/private/home')
        }
      } catch (err) {
        console.error('Session error' + err)
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      }
    },

    changePasswordV3: async ({ getters, commit, dispatch }, authData) => {
      const shaPw = sha256(authData.password).toString()
      const shaOldPw = sha256(authData.currentPassword).toString()
      try {
        let res = await axiosACN.post(
          '/sorgenia/V3/changepwd',
          {
            username: getters.username,
            newPassword: shaPw,
            oldPassword: shaOldPw
          },
          {
            headers: {
              Authorization: 'Bearer ' + getters.token,
              'Ocp-Apim-Subscription-Key': ENV_CONFIGS.ACN_SUB_KEY
            }
          }
        )

        if (res.data.errorCode) {
          switch (res.data.errorCode) {
            case '001':
              throw Error('WRONG_OLD_PASSWORD')
            case '1126':
              throw Error('PSW_CANNOT_BE_SAME')
            default:
              throw Error('UNKNOWN_ERROR')
          }
        }

        const expirationDate = res.data.expiresIn
        localStorage.setItem('username', getters.username || '')
        localStorage.setItem('token', res.data.accessToken || '')
        localStorage.setItem('refreshToken', res.data.refreshToken || '')
        localStorage.setItem('expirationDate', expirationDate || '')

        commit('authUser', {
          username: getters.username,
          token: res.data.accessToken,
          expirationDate: expirationDate,
          accountTypes: getters.accountTypes
        })
        const fromPath = router.history.current.path

        const fwdURL = router.currentRoute.query['forwardURL']
        if (fwdURL) {
          router.replace(fwdURL)
        } else if (
          fromPath === '/private/account' ||
          fromPath === '/private/profile/information'
        ) {
        } else {
          router.replace('/private/home')
        }
      } catch (err) {
        console.error('Session error' + err)
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      }
    },

    resetpwd: async ({ commit, dispatch }, authData) => {
      try {
        let res = await axiosACN.post('/sorgenia/V2/resetpwd1', {
          username: authData.username,
          email: authData.email,
          canaleProvenienza: 'MYS'
        })

        if (res.data.errorCode) {
          switch (res.data.errorCode) {
            case '500':
              throw Error('SERVER_ERROR')
            case '5002':
              throw Error('NO_MACTH')
            case '5007':
              throw Error('NO_VALID_EMAIL')
            default:
              throw Error('UNKNOWN_ERROR')
          }
        }

        const expirationDate = ''
        localStorage.setItem('username', authData.username || '')
        localStorage.setItem('token', res.data.access_token || '')
        localStorage.setItem('refreshToken', '')
        localStorage.setItem('expirationDate', '')

        commit('authUser', {
          username: authData.username,
          token: res.data.access_token,
          expirationDate: expirationDate
        })
      } catch (err) {
        console.error('Session error' + err)
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      }
    },

    updatePrivacy: async ({ getters, commit }, username) => {
      try {
        let res = await axiosACN.put('/sorgenia/updatePrivacy', username, {
          headers: {
            Authorization: 'Bearer ' + getters.token,
            'Ocp-Apim-Subscription-Key': ENV_CONFIGS.ACN_SUB_KEY
          }
        })
        if (res.data.status === 'OK') {
          commit('privacy', true)
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error('Session error' + err)
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      }
    },
    async sendReCaptcha({}, token) {
      const tokenBase64 = btoa(token)
      try {
        const response = await axiosACN.post(
          'sorgenia/sendReCaptcha',
          {
            token: tokenBase64
          },
          {
            headers: {
              Authorization: ENV_CONFIGS.BASIC_TOKEN
            }
          }
        )
        if (!response.data.outcome) {
          throw new Error('RECAPTCHA_ERROR')
        }
      } catch (error) {
        console.error(error)
        throw new Error('RECAPTCHA_ERROR')
      }
    }
  }
}
