<template>
  <v-app>
    <!--<v-app-bar app>
      <v-toolbar-title class="headline text-uppercase">
        <span>Vuetify</span>
        <span class="font-weight-light">MATERIAL DESIGN</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        text
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn text>
        <router-link v-if="isAuthenticated" to="about">About</router-link>
      </v-btn>
      <v-btn text>
        <router-link v-if="isAuthenticated" to="home">Home</router-link>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn text v-if="isAuthenticated">
        <div @click="changePassword">Cambia Password</div>
      </v-btn>
      <v-btn text>
        <router-link v-if="!isAuthenticated" to="login">Login</router-link>

        <div v-if="isAuthenticated" @click="logout">Logout</div>
      </v-btn>

    </v-app-bar>-->
    <Redirect
      v-if="mobilePlatform && !onRedirectSkipRoutes && this.$route.name"
      @close="mobilePlatform = null"
      :platform="mobilePlatform"
    />
    <template v-else>
      <HeaderPage v-if="$route.name && !onRedirectSkipRoutes" />
      <v-main>
        <router-view />
      </v-main>
      <FooterPage v-if="$route.name && !onRedirectSkipRoutes" />
    </template>
    <input type="hidden" name="env" :value="config.ENV" />
    <input type="hidden" name="admin" :value="config.ENABLE_ADMIN_LOGIN" />
  </v-app>
</template>

<script>
import Redirect from '@/views/redirect/Redirect'
import router from './router/router'
import HeaderPage from './components/header/HeaderNew'
import FooterPage from './components/footer/Footer'
import { mapActions, mapGetters } from 'vuex'
import { isOnMobileDeviceGetPlatform } from '@/js/utils.js'
import { ENV_CONFIGS } from '@/js/configs.js'

export default {
  name: 'App',
  components: {
    Redirect,
    HeaderPage,
    FooterPage
  },
  data() {
    return {
      mobilePlatform: null,
      config: ENV_CONFIGS
    }
  },
  computed: {
    ...mapGetters('notice', ['notices', 'noticeSkipped']),
    ...mapGetters('session', ['username', 'token']),
    isAuthenticated() {
      return this.$store.getters['session/isAuthenticated']
    },
    onRedirectSkipRoutes() {
      const redirectSkipRoutesWelcome = [
        'publicStandardWelcomeKit',
        'publicWelcomekitDone',
        'publicWelcomekit',
        'publicKnowSorgenia',
        'publicOurImpact',
        'publicCleanEnergy',
        'publicCleanEnergyNew',
        'publicBillSorgenia'
      ]
      const redirectSkipRoutesTutorial = [
        'publicStandardTutorial',
        'publicTutorial',
        'publicTutorialProfile',
        'publicTutorialBillSorgenia',
        'publicTutorialAutoReading',
        'publicTutorialOneshotPayment',
        'publicTutorialPaymentSummary',
        'publicTutorialSearchSupply'
      ]
      const redirectSkipRoutesFeedback = [
        'publicFeedback',
        'publicFeedback-survey'
      ]
      const redirectSkipRoutesSupport = [
        'publicDreamServiceList',
        'publicDreamServiceDetail',
        'publicDreamServiceDone'
      ]
      const redirectSkipRoutesShop = ['myshop', 'myshopdeeplink', 'openmyshop']
      const redirectSkipRoutesCheckup = [
        'checkup',
        'checkupShort',
        'checkupComplete',
        'checkupCompleted',
        'checkupSent'
      ]

      return [
        ...redirectSkipRoutesWelcome,
        ...redirectSkipRoutesTutorial,
        ...redirectSkipRoutesFeedback,
        ...redirectSkipRoutesSupport,
        ...redirectSkipRoutesShop,
        ...redirectSkipRoutesCheckup
      ].includes(this.$route.name)
    }
  },
  methods: {
    ...mapActions('notice', ['getNotices']),
    ...mapActions('account', ['getMainClient']),
    ...mapActions('loyalty', ['getMemberStatus', 'newTransaction']),
    logout() {
      this.$store.dispatch('session/logout')
    },
    changePassword() {
      router.push({ name: 'changePassword' })
    },
    setIsOnMobileClass() {
      let htmlEl = document.getElementsByTagName('html')[0]
      htmlEl.classList.add('on-mobile-device')
    }
    // async hideBadgeRecaptcha() {
    //   await this.$recaptchaLoaded()
    //   let recaptcha = this.$recaptchaInstance
    //   recaptcha.hideBadge()
    // }
  },
  created() {
    if (!this.isAuthenticated) {
      this.$store.dispatch('analytics/setupTracking', false, { root: true })
    }
    const link = (platform) => {
      let link = ''
      switch (platform) {
        case 'ios':
          link = 'https://apps.apple.com/it/app/mysorgenia/id593806212/'
          break
        case 'android':
          // Link to Google Play Market.
          link = 'https://play.google.com/store/apps/details?id=my.sorgenia'
          break
      }
      return link
    }

    // this.hideBadgeRecaptcha()

    // Disabling device detection
    let platform = isOnMobileDeviceGetPlatform()
    let fromDemLink =
      (this.$route.query['dem'] && this.$route.query['dem'] === 'true') ||
      (this.$route.query['forwardURL'] &&
        this.$route.query['forwardURL'].indexOf('dem=true') > -1)

    if (platform && fromDemLink && router.currentRoute['name'] !== 'redirect') {
      this.setIsOnMobileClass()
      // router.push({ path: 'redirect', query: { platform: platform } })
      const url = link(platform)
      // window.location.href = url// ('https://www.google.com'
      window.location.replace(url)
    }

    // TODO remove for checkup route
    this.getNotices()

    if (this.username && this.token) {
      this.getMainClient().then((mainClient) => {
        if (!mainClient.isOnlyFV) {
          this.getMemberStatus({ ext_uuid: mainClient.clientOwnerCode }).then(
            (memberStatus) => {
              if (memberStatus.user_registered) {
                const body = {
                  event: '2',
                  action: '21',
                  detail1: 'MYSORGENIA'
                }
                this.newTransaction(body)
              }
            }
          )
        }
      })
    }

    if (platform === 'android' || platform === 'ios')
      this.mobilePlatform = platform
  },
  watch: {
    notices(noticeList) {
      if (
        !noticeList ||
        !noticeList.length ||
        noticeList.length === 0 ||
        this.noticeSkipped
      )
        return

      router.push({ name: 'notice' })
    }
  }
}
</script>

<style lang="scss">
@import './styles/main.scss';

// Thihs is only needed to overcome the issue with the vuetify theme until it cannot be disabled
.v-application .primary {
  background-color: unset !important;
}

.v-slider__thumb .primary {
  background: #4fd0ff !important;
  border-color: #fff !important;
}
</style>
