var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"558","max-height":"588","overlay-opacity":"0.6","content-class":"serviceUnavailableDialog"},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c('div',{staticClass:"serviceUnavailableDialog"},[(_vm.canClose)?_c('div',{staticClass:"close"},[_c('img',{attrs:{"src":require("../..//assets/icons/close.svg"),"alt":"close","width":"24","height":"24"},on:{"click":function($event){_vm.dialogOpen = false}}})]):_vm._e(),_c('div',{staticClass:"body mt-4"},[(_vm.imageResponse === 'ok')?_c('img',{staticClass:"mb-8",attrs:{"src":require("../../assets/icons/Illo.svg")}}):_vm._e(),(_vm.imageResponse === 'KO-nonInviata')?_c('img',{staticClass:"mb-8",attrs:{"src":require("../../assets/icons/IlloNotInvied.svg")}}):_vm._e(),(_vm.imageResponse === 'KO-500')?_c('img',{staticClass:"mb-8",attrs:{"src":require("../../assets/icons/IlloError.svg")}}):_vm._e(),_c('div',{staticClass:"SubheadingXL mb-2"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"BodyL"},[_c('span',[_vm._v(" "+_vm._s(_vm.body)+" ")]),_c('span',{staticClass:"BodyL-Strong"},[_vm._v(" "+_vm._s(_vm.bodyStrong)+" ")])]),_c('div',{staticClass:"containerButton"},[(_vm.cta1)?_c('MYSButton',{attrs:{"buttonText":_vm.cta1,"width":"251","buttonClass":"primaryButton mt-8"},on:{"buttonClick":function($event){_vm.$emit('update:serviceUnavailableDialogOpen', false)
            _vm.goHomeOrGoForm()
            _vm.retry()}}}):_vm._e(),(_vm.cta2)?_c('MYSButton',{attrs:{"buttonText":_vm.cta2,"width":"280","buttonClass":_vm.ctaExternal
              ? 'primaryInvertedButtonBorded GMEButton mt-4'
              : 'primaryInvertedButtonBorded mt-4'},on:{"buttonClick":function($event){_vm.ctaExternal
              ? _vm.toGME()
              : _vm.$emit('update:serviceUnavailableDialogOpen', false)
            _vm.goFAQ()
            _vm.returnToHome()}}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }