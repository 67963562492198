<template>
  <div class="payment">
    <div class="payment__content">
      <template v-if="!newPaymentMethod">
        <h5 class="HeadingL mb-8">Scegli il metodo di pagamento</h5>
        <div
          class="payment__btn"
          v-for="(meth, i) in methodsList"
          :key="i"
          @click="storePaymentMethod(meth, false)"
        >
          <div
            class="BodyL"
            v-if="meth.methodType === PAYMENT_METHODS.CREDIT_CARD"
          >
            Carta di credito {{ formatCreditCard(meth.creditCardNumber) }}
          </div>
          <div class="BodyL" v-else>
            Addebito in conto corrente {{ maskedIban(meth.iban) }}
          </div>
        </div>
        <div
          @click="newPaymentMethod = 'newPaySelection'"
          class="newPaymentMethod"
        >
          <img src="../../../../assets/icons/Plus-bold.svg" />
          <div class="ButtonL">Aggiungi nuovo metodo di pagamento</div>
        </div>
      </template>
      <template v-else-if="newPaymentMethod === 'newPaySelection'">
        <div class="HeadingL mb-4">
          Scegli il metodo di pagamento alternativo desiderato.
        </div>
        <div class="BodyL mb-8">
          Ricorda che completando con successo il cambio del metodo di
          pagamento, la modifica verrà apportata solamente alla fornitura
          selezionata.
        </div>
        <div class="payment__btn" @click="newPaymentMethod = 'newBankAccount'">
          <h5>Addebito in conto corrente</h5>
        </div>
        <div
          class="payment__btn mb-8"
          @click="newPaymentMethod = 'newCreditCard'"
        >
          <h5>Addebito carta di credito</h5>
        </div>
        <MYSButton
          v-if="!editMode"
          buttonClass="primaryInvertedButtonLBorded"
          @buttonClick="newPaymentMethod = ''"
          buttonText="Annulla"
        />
      </template>
      <template v-else-if="newPaymentMethod === 'newBankAccount'">
        <v-text-field
          id="newIban"
          label="Nuovo Iban"
          class="auth__input"
          v-model.trim="newIban"
          :error-messages="newIbanErrors"
          @blur="v$.newIban.$touch()"
        ></v-text-field>
        <div class="d-flex align-center justify-space-between">
          <h5>L’intestatario del conto corrente è lo stesso della fornitura</h5>
          <v-switch v-model="sameHolder" color="#70D736" inset></v-switch>
        </div>
        <template v-if="!sameHolder">
          <v-text-field
            label="Nome intestatario"
            class="auth__input mb-5"
            v-model.trim="newIbanName"
            :error-messages="newIbanNameErrors"
            @blur="v$.newIbanName.$touch()"
          ></v-text-field>
          <v-text-field
            label="Cognome intestatario"
            class="auth__input mb-4"
            v-model.trim="newIbanSurname"
            :error-messages="newIbanSurnameErrors"
            @blur="v$.newIbanSurname.$touch()"
          ></v-text-field>
          <v-text-field
            label="Codice fiscale"
            class="auth__input mb-4"
            v-model.trim="newIbanCf"
            :error-messages="newIbanCfErrors"
            @blur="v$.newIbanCf.$touch()"
          ></v-text-field>
        </template>
        <MYSButton
          buttonClass="primaryButtonL mr-5"
          @buttonClick="addPaymentMethod"
          buttonText="Aggiungi"
        />
        <MYSButton
          buttonClass="primaryInvertedButtonLBorded"
          @buttonClick="newPaymentMethod = 'newPaySelection'"
          buttonText="Annulla"
        />
      </template>
    </div>
    <div v-show="showSiaFrame" class="siaDialog">
      <iframe
        id="fiberSiaCC"
        v-if="siaUrl"
        @load="iframeUrlChanged"
        :src="siaUrl"
        frameborder="0"
      ></iframe>
    </div>
    <div v-if="showPayPalFrame" class="payPalDialog">
      <iframe
        id="fiberPayPalCC"
        v-if="payPalUrl"
        @load="iframeUrlChanged"
        :src="payPalUrl"
        frameborder="0"
      ></iframe>
    </div>
    <PopupErrorIban ref="popup"></PopupErrorIban>
  </div>
</template>
<script>
import PopupErrorIban from '@/components/upselling/fiber/PopupErrorIban'
import Consts from '@/js/constants'
import { checkIban, getRequiredValidationError, validateCF } from '@/js/utils'
import { alphaNum, required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
import MYSButton from '../../../../components/global/MYSButton.vue'
import { maskIban } from '@/js/utils'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'paymentEle',
  props: ['editMode'],
  data() {
    return {
      methodsList: [],
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,
      paymentData: null,
      newPaymentMethod: null,
      sameHolder: true,
      newIban: '',
      newIbanName: '',
      newIbanSurname: '',
      newIbanCf: '',
      siaUrl: '',
      payPalUrl: '',
      showSiaFrame: false,
      showPayPalFrame: false
    }
  },
  mounted() {},
  watch: {
    newIbanCf(newVal) {
      this.newIbanCf = newVal.toUpperCase()
    },
    newIban(newVal) {
      this.newIban = newVal.toUpperCase().replace(' ', '')
    },
    newPaymentMethod(val, oldval) {
      switch (val) {
        case 'newCreditCard':
          if (this.$store.getters['upsellingComm/client']) {
            this.getDetailForClient({
              clientOwnerCode:
                this.$store.getters['upsellingComm/client'].clientCode
            }).then((resp) => {
              this.getSiaUrl({ email: resp.contactMail }).then((url) => {
                this.siaUrl = url
                this.showSiaFrame = true
              })
              this.trackTapEvent({
                name: 'upsellingcom_mdpnewccr_tap',
                params: {
                  type: this.$store.getters['upsellingComm/commodityType'],
                  client_selected:
                    this.$store.getters['upsellingComm/address']
                      .clientOwnerCode || ''
                }
              })
            })
          } else {
            this.getSiaUrl({ email: this.clientDetails.contactMail }).then(
              (url) => {
                this.siaUrl = url
                this.showSiaFrame = true
              }
            )
            this.trackTapEvent({
              name: 'upsellingcom_mdpnewccr_tap',
              params: {
                type: this.$store.getters['upsellingComm/commodityType'],
                client_selected:
                  this.$store.getters['upsellingComm/address']
                    .clientOwnerCode || ''
              }
            })
          }
          break
        case 'newBankAccount':
          if (oldval === 'newBankAccount') {
            this.trackTapEvent({
              name: 'upsellingcom_mdpnewbank_undo',
              params: {
                type: this.$store.getters['upsellingComm/commodityType'],
                client_selected:
                  this.$store.getters['upsellingComm/address']
                    .clientOwnerCode || ''
              }
            })
          }
        case 'newPaySelection':
          if (oldval === 'newBankAccount') {
            this.trackTapEvent({
              name: 'upsellingcom_mdpnewbank_undo',
              params: {
                type: this.$store.getters['upsellingComm/commodityType'],
                client_selected:
                  this.$store.getters['upsellingComm/address']
                    .clientOwnerCode || ''
              }
            })
          } else if (oldval === 'newCreditCard') {
            this.trackTapEvent({
              name: 'upsellingcom_mdpnewccr_undo',
              params: {
                type: this.$store.getters['upsellingComm/commodityType'],
                client_selected:
                  this.$store.getters['upsellingComm/address']
                    .clientOwnerCode || ''
              }
            })
          } else {
            this.trackTapEvent({
              name: 'upsellingcom_mdpnew_tap',
              params: {
                type: this.$store.getters['upsellingComm/commodityType'],
                client_selected:
                  this.$store.getters['upsellingComm/address']
                    .clientOwnerCode || ''
              }
            })
          }
          break
        default:
          this.trackTapEvent({
            name: 'upsellingcom_mdpnew_undo',
            params: {
              type: this.$store.getters['upsellingComm/commodityType'],
              client_selected:
                this.$store.getters['upsellingComm/address'].clientOwnerCode ||
                ''
            }
          })
          break
      }
    },
    'v$.newIban.$error': function (val) {
      val
        ? this.setValidationErrors('IBAN_CODE_INVALID')
        : this.deleteValidationError('IBAN_CODE_INVALID')
    },
    'v$.newIbanName.$error': function (val) {
      val
        ? this.setValidationErrors('IBAN_NAME_INVALID')
        : this.deleteValidationError('IBAN_NAME_INVALID')
    },
    'v$.newIbanSurname.$error': function (val) {
      val
        ? this.setValidationErrors('IBAN_SURNAME_INVALID')
        : this.deleteValidationError('IBAN_SURNAME_INVALID')
    },
    'v$.newIbanCf.$error': function (val) {
      val
        ? this.setValidationErrors('IBAN_FISCAL_CODE_INVALID')
        : this.deleteValidationError('IBAN_FISCAL_CODE_INVALID')
    },
    sameHolder(val) {
      if (!val) {
        this.v$.newIbanName.$error
          ? this.setValidationErrors('IBAN_NAME_INVALID')
          : this.deleteValidationError('IBAN_NAME_INVALID')
        this.v$.newIbanSurname.$error
          ? this.setValidationErrors('IBAN_SURNAME_INVALID')
          : this.deleteValidationError('IBAN_SURNAME_INVALID')
        this.v$.newIbanCf.$error
          ? this.setValidationErrors('IBAN_FISCAL_CODE_INVALID')
          : this.deleteValidationError('IBAN_FISCAL_CODE_INVALID')
      } else {
        this.deleteValidationError('IBAN_NAME_INVALID')
        this.deleteValidationError('IBAN_SURNAME_INVALID')
        this.deleteValidationError('IBAN_FISCAL_CODE_INVALID')
      }
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['client', 'activeLine', 'commodityType']),
    ...mapGetters('account', ['accountProperties']),
    newIbanErrors() {
      let errArray = []

      if (!this.v$.newIban.$dirty) return errArray

      this.v$.newIban.required.$invalid === true &&
        errArray.push("L'IBAN è obbligatorio")
      this.v$.newIban.alphaNum === false &&
        errArray.push('Il campo IBAN non è valido')
      this.v$.newIban.validateIban === false &&
        errArray.push('Il campo IBAN non è valido')

      return errArray
    },
    newIbanNameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanName',
        'Il nome intestatario è obbligatorio'
      )
    },
    newIbanSurnameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanSurname',
        'Il cognome intestatario è obbligatorio'
      )
    },
    newIbanCfErrors() {
      let errArray = []

      if (!this.v$.newIbanCf.$dirty) return errArray

      this.v$.newIbanCf.required.$invalid === true &&
        errArray.push('Il codice fiscale intestatario è obbligatorio')
      this.v$.newIbanCf.validateCF === false &&
        errArray.push('Formato codice fiscale non valido')

      return errArray
    }
  },
  beforeMount() {
    // Get the existing payment methods list
    if (this.client) {
      this.getMethods({ clientCode: this.client.clientOwnerCode }).then(
        (methods) => {
          this.methodsList = methods.filter(
            (meth) =>
              meth.methodType === this.PAYMENT_METHODS.CREDIT_CARD ||
              meth.methodType === this.PAYMENT_METHODS.BANK_ACCOUNT
          )
          this.trackTapEvent({
            name: 'payment_method_viewed',
            params: {
              funnel_segment: this.client?.clientType?.toLowerCase(),
              services_to_subscribe: this.commodityType,
              flow: 'subscription',
              payment_methods_set: this.methodsList.length
            }
          })
        }
      )
    }
    if (this.editMode) {
      this.newPaymentMethod = 'newPaySelection'
    }
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'setPaymentMethod',
      'setValidationErrors',
      'deleteValidationError',
      'setFlagNewIban'
    ]),
    ...mapActions('upsellingFiber', ['getSiaUrl']),
    ...mapActions('upsellingComm', ['getPayPalUrl', 'checkIBAN']),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('payment', ['getMethods']),
    ...mapActions('analytics', ['trackTapEvent']),
    maskedIban(iban) {
      return maskIban(iban)
    },
    addPaymentMethod(ccParams) {
      switch (this.newPaymentMethod) {
        case 'newBankAccount':
          this.paymentData = {
            businessName:
              this.newIbanName ||
              this.client.firstName + ' ' + this.newIbanSurname ||
              this.client.lastName,
            methodType: this.PAYMENT_METHODS.BANK_ACCOUNT,
            iban: this.newIban,
            ibanSubscriberFirstName: !this.sameHolder
              ? this.newIbanName
              : this.client.firstName,
            ibanSubscriberLastName: !this.sameHolder
              ? this.newIbanSurname
              : this.client.lastName,
            ibanSubscriberFiscalCode: !this.sameHolder
              ? this.newIbanCf
              : this.client.fiscalCode
          }
          break
        case 'newCreditCard':
          this.paymentData = {
            methodType: this.PAYMENT_METHODS.CREDIT_CARD,
            fiscalCode: this.client.fiscalCode,
            ...ccParams
          }
          break
        default:
          break
      }
      this.storePaymentMethod(this.paymentData, true)
    },
    storePaymentMethod(newMeth, isNewMethod) {
      if (
        newMeth.methodType === this.PAYMENT_METHODS.BANK_ACCOUNT &&
        isNewMethod
      ) {
        let isValidIban = checkIban(document.getElementById('newIban'))
        isValidIban
          .then((resp) => {
            this.methodsList.push(newMeth)
            this.setPaymentMethod({ ...newMeth, sameHolder: this.sameHolder })
            this.setFlagNewIban(isNewMethod)
            if (!this.editMode) {
              this.$emit('nextStep', 'notrack') //per ora si gestisce con notrack se la sonda non deve essere inviata, inseguito queste vecchie verranno eliminate tutte dalla radice
            } else {
              this.$emit('prevStep')
            }

            this.sendSonda(isNewMethod, 'ssd')
          })
          .catch((resp) => {
            this.$refs.popup.typeError(resp)
          })
      } else {
        this.setPaymentMethod(newMeth)
        this.setFlagNewIban(isNewMethod)
        if (newMeth.methodType === this.PAYMENT_METHODS.BANK_ACCOUNT) {
          //nuova sonda payment_method_selected - va mandata con params new_payment_method true o false in base a isNewMethod
          this.sendSonda(isNewMethod, 'ssd')
        } else {
          //nuova sonda payment_method_selected - va mandata con params new_payment_method true o false in base a isNewMethod
          this.sendSonda(isNewMethod, 'ccr')
        }
        if (!this.editMode) {
          this.$emit('nextStep', 'notrack')
        } else {
          this.$emit('prevStep')
        }
      }
    },
    iframeUrlChanged(event) {
      try {
        const loc = event.target.contentWindow.location.href
        if (loc.indexOf('sia/back.html') > -1) {
          this.siaUrl = ''
          this.showSiaFrame = false
          this.newPaymentMethod = 'newPaySelection'
        } else if (loc.indexOf('sia/done.html') > -1) {
          const queryParams = loc.substring(loc.indexOf('?') + 1).split('&')
          let queryParamsObj = {}
          queryParams.forEach((qp) => {
            const parts = qp.split('=')
            queryParamsObj[parts[0]] = parts[1]
          })

          const ccParams = {
            aliasPan: queryParamsObj.PANALIAS,
            creditCardCircuit: queryParamsObj.NETWORK,
            creditCardNumber:
              '****' + '****' + '****' + queryParamsObj.PANALIASTAIL,
            creditCardExpirationDate: queryParamsObj.PANALIASEXPDATE,
            crecurr: queryParamsObj.CRECURR,
            trecurr: queryParamsObj.TRECURR
          }
          this.addPaymentMethod(ccParams)

          this.siaUrl = ''
          this.showSiaFrame = false
        }
      } catch {
        // do nothing. it means we have no access to the iframe data because it is crossdomain
      }
    },
    sendSonda(isNewMethod, type) {
      this.trackTapEvent({
        name: 'payment_method_selected',
        params: {
          funnel_segment: this.client?.clientType?.toLowerCase(),
          services_to_subscribe: this.commodityType,
          flow: 'subscription',
          payment_method:
            type === 'ssd'
              ? this.PAYMENT_METHODS.BANK_ACCOUNT.toLowerCase()
              : this.PAYMENT_METHODS.CREDIT_CARD.toLowerCase(), //da chiedere
          new_payment_method: isNewMethod
        }
      })
    },
    formatCreditCard(cc) {
      return cc.replace(/(.{4})/g, '$1 ').trim()
    }
  },
  validations: {
    newIban: {
      required,
      alphaNum,
      validateIban: (value, vm) => {
        return vm.checkIBAN(value)
      }
    },
    newIbanName: { required },
    newIbanSurname: { required },
    newIbanCf: { required, validateCF }
  },
  components: {
    PopupErrorIban,
    MYSButton
  }
}
</script>
<style lang="scss" scoped>
.payment {
  &__content {
    max-width: 652px;
  }

  color: black;

  &__info {
    font-weight: 500;
  }

  &__desc {
    line-height: 1.25;
  }

  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 54px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 16px;
    padding: 0 16px;
    position: relative;
    cursor: pointer;

    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 15px;
      top: calc(50% - 12px);
    }
  }

  .siaDialog {
    height: 80vh;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
.newPaymentMethod {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 48px;
  cursor: pointer;
  img {
    margin-bottom: 2px;
    margin-right: 8px;
  }
  .ButtonL {
    color: $srg-blue;
  }
}
</style>
<style lang="scss">
.payment__content {
  .error--text {
    .v-messages__message {
      display: flex;
      align-items: center;
      margin-right: 2px;
      margin-top: 6px;
      &::before {
        margin-right: 6px;
        content: url('@/assets/icons/Error.svg');
      }
    }
    input {
      color: red !important;
    }
  }
  .v-input__icon .mdi-menu-down::before {
    content: url('@/assets/icons/search.svg');
    transform: none !important;
  }
  .v-input__icon .mdi-menu-down {
    transform: none !important;
    -webkit-transform: none !important;
  }
}
</style>
