<template>
  <page
    title="Invia i dati catastali"
    subTitle="Abbiamo bisogno dei tuoi dati catastali per comunicare all'Agenzia delle Entrate la registrazione dell'utenza."
    page-name="Dati identificativi"
    longBreadcrumbs="Dati catastali"
    longBreadcrumbsAction="cadastralAction"
  >
    <TcVars />
    <div class="cadastral mt-6">
      <div class="cadastral__title mb-7">Dati identificativi catastali</div>
      <v-row no-gutters class="mb-10">
        <v-col cols="6" class="pr-4">
          <v-select
            v-model="unitType"
            :items="[
              { val: 'T', label: 'Terreni' },
              { val: 'F', label: 'Fabbricati' }
            ]"
            item-text="label"
            item-value="val"
            name="unitType"
            :error-messages="unitTypeErrors"
            placeholder="Seleziona"
            persistent-placeholder
            class="mb-3"
            label="TIPO DI UNITÀ"
          >
            <template #item="{ item }">
              <span class="itemTypeOwner">
                {{ item.label }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6" class="pl-4">
          <v-text-field
            tabindex="1"
            :error-messages="particellaErrors"
            @blur="v$.particella.$touch()"
            class="auth__input"
            label="PARTICELLA"
            placeholder="Es. 587"
            persistent-placeholder
            color="#6e7689"
            v-model="particella"
          >
            <template v-slot:append-outer>
              <div class="iconInfo particella">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('particella')"
                />
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-10">
        <v-col cols="6" class="pr-4">
          <v-text-field
            tabindex="1"
            :error-messages="sectionErrors"
            @blur="v$.section.$touch()"
            class="auth__input"
            label="SEZIONE (SE PRESENTE)"
            color="#6e7689"
            v-model="section"
            placeholder="Es. SNA"
            persistent-placeholder
          >
            <template v-slot:append-outer>
              <div class="iconInfo section">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('section')"
                />
              </div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" class="pl-4">
          <v-text-field
            tabindex="1"
            :error-messages="subalternErrors"
            @blur="v$.subaltern.$touch()"
            class="auth__input"
            label="SUBALTERNO"
            color="#6e7689"
            v-model="subaltern"
            placeholder="Es. 12"
            persistent-placeholder
          >
            <template v-slot:append-outer>
              <div class="iconInfo subaltern">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('subaltern')"
                />
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-5">
        <v-col cols="6" class="pr-4">
          <v-text-field
            tabindex="1"
            :error-messages="sheetErrors"
            @blur="v$.sheet.$touch()"
            class="auth__input"
            label="FOGLIO"
            color="#6e7689"
            placeholder="Es. 12"
            persistent-placeholder
            v-model="sheet"
          >
            <template v-slot:append-outer>
              <div class="iconInfo sheet">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('sheet')"
                />
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div class="cadastral__title mb-2">Dati identificativi catastali</div>
      <div class="cadastral__subTitle mb-7">
        Compila solo se nel tuo comune vige il sistema del Catasto Tavolare
      </div>
      <v-row no-gutters class="mb-10">
        <v-col cols="6" class="pr-4">
          <v-select
            v-model="particellaType"
            :items="[
              { val: 'F', label: 'Fondiaria' },
              { val: 'E', label: 'Edificabile' }
            ]"
            item-text="label"
            item-value="val"
            name="particellaType"
            placeholder="Seleziona"
            persistent-placeholder
            class="mb-3"
            label="TIPO PARTICELLA"
          >
            <template #item="{ item }">
              <span class="itemTypeOwner">
                {{ item.label }}
              </span>
            </template>
            <template v-slot:append-outer>
              <div class="iconInfo particellaType">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('particellaType')"
                />
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6" class="pl-4">
          <v-text-field
            tabindex="1"
            :error-messages="particellaFollowErrors"
            @blur="v$.particellaFollow.$touch()"
            class="auth__input"
            label="SEGUE PARTICELLA"
            color="#6e7689"
            v-model="particellaFollow"
            placeholder="Es. AB12"
            persistent-placeholder
          >
            <template v-slot:append-outer>
              <div class="iconInfo particellaFollow">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('particellaFollow')"
                />
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div class="mb-10 pb-10">
        <v-btn
          width="156"
          height="54"
          color="primary"
          class="mr-6"
          :disabled="disabled"
          @click="dialogConferm = true"
          >Continua</v-btn
        >
        <v-btn
          width="200"
          height="54"
          color="primary"
          text
          @click="noDataRequest"
          >Non ho questi dati</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="dialogInfo.show" width="555">
      <div class="dialogCatastral">
        <div class="d-flex justify-space-between align-start mb-5">
          <div class="dialogCatastral__title" v-html="dialogInfo.title" />
          <img
            src="../../../assets/icons/close-blu.svg"
            @click="dialogInfo.show = false"
          />
        </div>
        <div class="dialogCatastral__desc" v-html="dialogInfo.desc" />
        <div class="d-flex justify-center align-center">
          <v-btn
            width="156"
            height="54"
            color="primary"
            @click="dialogInfo.show = false"
            >Ho capito</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogConferm" width="555">
      <div class="dialogCatastral">
        <div class="d-flex justify-space-between align-start mb-5">
          <div class="dialogCatastral__title">Confermi le informazioni?</div>
          <img
            src="../../../assets/icons/close-blu.svg"
            @click="dialogConferm = false"
          />
        </div>
        <div class="dialogCatastral__desc">
          Se confermi le informazioni non potrai tornare indietro per
          modificarle.
        </div>
        <div class="d-flex justify-center align-center">
          <v-btn
            width="229"
            height="54"
            color="primary"
            class="mr-2"
            @click="request"
            >Conferma e invia</v-btn
          >
          <v-btn
            width="229"
            height="54"
            color="primary"
            text
            @click="
              trackTapEvent({
                name: 'cadastral_cancelData_tap',
                params: paramsTrack
              }),
                (dialogConferm = false)
            "
            >Annulla</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <div class="contentLoader" v-if="loader">
      <v-progress-circular
        class="loader"
        :size="100"
        indeterminate
      ></v-progress-circular>
    </div>
  </page>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import { useVuelidate } from '@vuelidate/core'
import { alphaNum, maxLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Template from './Template'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'cadastralData',
  components: {
    page: Template,
    TcVars
  },
  data() {
    return {
      unitType: null,
      particella: null,
      section: null,
      subaltern: null,
      sheet: null,
      particellaType: null,
      particellaFollow: null,
      dialogInfo: {
        show: false,
        title: '',
        desc: ''
      },
      dialogConferm: false,
      loader: false
    }
  },
  created() {
    this.unitType = this.cadastralData.tipoUnita || null
    this.section = this.cadastralData.sezione || null
    this.sheet = this.cadastralData.foglio || null
    this.particella = this.cadastralData.particella || null
    this.particellaFollow = this.cadastralData.segueParticella || null
    this.particellaType = this.cadastralData.tipo || null
    this.subaltern = this.cadastralData.subalterno || null
  },
  mounted() {
    this.trackTapEvent({
      name: 'cadastral_clientDataInsert_tap',
      params: this.paramsTrack
    })
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail', 'cadastralData']),
    paramsTrack() {
      const supplyCode = this.$route.params.supplyCode
      return {
        supply_type:
          this.supplyDetail &&
          this.supplyDetail[supplyCode].serviceType.toLowerCase(),
        pr_code: this.$route.params.supplyCode,
        client_code:
          this.supplyDetail &&
          this.supplyDetail[this.$route.params.supplyCode].clientOwnerCode,
        client_type:
          this.supplyDetail &&
          this.supplyDetail[supplyCode].clientOwnerType.toLowerCase()
      }
    },
    disabled() {
      return this.v$.$invalid
    },
    unitTypeErrors() {
      let errArray = []
      if (!this.v$.unitType.$dirty) return errArray
      this.v$.unitType.required.$invalid === true &&
        errArray.push('Il tipo di unità è obbligatorio')
      return errArray
    },
    particellaErrors() {
      let errArray = []
      if (!this.v$.particella.$dirty) return errArray
      this.v$.particella.$invalid === true &&
        errArray.push('Il campo particella è obbligatorio')
      this.v$.particella.maxLength.$invalid === true &&
        errArray.push('Contiene max 5 valori alfanumerici')
      this.v$.particella.alphaNum === false &&
        errArray.push('Può contenere solo valori alfanumerici')
      return errArray
    },
    sectionErrors() {
      let errArray = []
      if (!this.v$.section.$dirty) return errArray
      this.v$.section.maxLength.$invalid === true &&
        errArray.push('Contiene max 3 valori alfanumerici')
      this.v$.section.alphaNum === false &&
        errArray.push('Può contenere solo valori alfanumerici')
      return errArray
    },
    subalternErrors() {
      let errArray = []
      if (!this.v$.subaltern.$dirty) return errArray
      this.v$.subaltern.maxLength.$invalid === true &&
        errArray.push('Contiene max 4 valori alfanumerici')
      this.v$.subaltern.alphaNum === false &&
        errArray.push('Può contenere solo valori alfanumerici')
      return errArray
    },
    sheetErrors() {
      let errArray = []
      if (!this.v$.sheet.$dirty) return errArray
      this.v$.sheet.required.$invalid === true &&
        errArray.push('Il campo foglio è obbligatorio')
      this.v$.sheet.maxLength.$invalid === true &&
        errArray.push('Contiene max 5 valori alfanumerici')
      this.v$.sheet.alphaNum === false &&
        errArray.push('Può contenere solo valori alfanumerici')
      return errArray
    },
    particellaFollowErrors() {
      let errArray = []
      if (!this.v$.particellaFollow.$dirty) return errArray
      this.v$.particellaFollow.maxLength.$invalid === true &&
        errArray.push('Contiene max 4 valori alfanumerici')
      this.v$.particellaFollow.alphaNum === false &&
        errArray.push('Può contenere solo valori alfanumerici')
      return errArray
    }
  },
  methods: {
    ...mapMutations('supply', ['setCadastralData']),
    ...mapActions('supply', ['sendCadastralData', 'getCadastralData']),
    ...mapActions('analytics', ['trackTapEvent']),
    setDialogInfo(type) {
      if (type === 'particella') {
        this.dialogInfo.title = 'Cos’è la particella?'
        this.dialogInfo.desc =
          'La particella è una parte di terreno. In genere è indicata da un numero.'
      } else if (type === 'section') {
        this.dialogInfo.title = 'Cos’è la sezione?'
        this.dialogInfo.desc =
          'È un codice alfanumerico presente nella visura di un fabbricato. Questo è un esempio: A12'
      } else if (type === 'sheet') {
        this.dialogInfo.title = 'Cos’è il foglio?'
        this.dialogInfo.desc =
          "Il foglio è l'unità territoriale in cui ogni comune viene suddiviso per il catasto. "
      } else if (type === 'subaltern') {
        this.dialogInfo.title = 'Cos’è il subalterno?'
        this.dialogInfo.desc =
          'Il subalterno identifica la singola unità immobiliare. Può anche non esserci.'
      } else if (type === 'particellaType') {
        this.dialogInfo.title = 'Cos’è ‘tipo particella’?'
        this.dialogInfo.desc =
          'Si tratta di una porzione di terreno continua fisicamente, con lo stesso possessore, con la stessa qualità e classe di coltura e ubicata nello stesso comune.'
      } else if (type === 'particellaFollow') {
        this.dialogInfo.title = 'Cos’è ‘segue particella’?'
        this.dialogInfo.desc =
          'È un’estensione della particella: si indica inserendo 4 caratteri.<br/>Un esempio: AB12'
      }

      this.trackTapEvent({
        name: 'cadastral_info_tap',
        params: { ...this.paramsTrack, info_data: type }
      })

      this.dialogInfo.show = true
    },
    request() {
      this.dialogConferm = false
      this.loader = true
      this.cadastralData.tipoUnita = this.unitType || ''
      this.cadastralData.sezione = this.section || ''
      this.cadastralData.foglio = this.sheet || ''
      this.cadastralData.particella = this.particella || ''
      this.cadastralData.segueParticella = this.particellaFollow || ''
      this.cadastralData.tipo = this.particellaType || ''
      this.cadastralData.subalterno = this.subaltern || ''
      this.cadastralData.motivazioneDatiNonCompilati = ''

      this.setCadastralData(this.cadastralData)

      this.trackTapEvent({
        name: 'cadastral_confirmData_tap',
        params: this.paramsTrack
      })
      this.getCadastralData({
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      }).then((resp) => {
        if (!resp.ricezioneDatiCatastali) {
          this.sendCadastralData().then((resp) => {
            if (resp) {
              this.loader = false
              this.$router.push({
                name: 'cadastralSuccess',
                params: { sendRequest: true }
              })
            } else {
              this.loader = false
            }
          })
        } else {
          this.loader = false
          this.$router.push({ name: 'cadastralSuccess' })
        }
      })
    },
    noDataRequest() {
      this.$router.push({ name: 'cadastralNoData' })
    }
  },
  validations: {
    unitType: { required },
    particella: { required, maxLength: maxLength(5), alphaNum },
    section: { maxLength: maxLength(3), alphaNum },
    subaltern: { maxLength: maxLength(4), alphaNum },
    sheet: { required, maxLength: maxLength(5), alphaNum },
    particellaFollow: { maxLength: maxLength(4), alphaNum }
  }
}
</script>
<style lang="scss" scoped>
.cadastral {
  width: 752px;
  &__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 1.3px;
  }
  &__subTitle {
    font-size: 16px;
    color: black;
    line-height: 1.4;
    letter-spacing: 1px;
  }
}
.contentLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.48);
  opacity: 0.6;
}
.v-dialog__content {
  .dialogCatastral {
    background: white;
    padding: 40px;
    img {
      cursor: pointer;
    }
    &__title {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 1px;
    }
    &__desc {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
  }
}
</style>
<style lang="scss">
.cadastral {
  .iconInfo {
    position: absolute;
    top: -12px;
    cursor: pointer;
    &.particellaFollow {
      left: 148px;
    }
    &.particellaType {
      left: 135px;
    }
    &.sheet {
      left: 59px;
    }
    &.subaltern {
      left: 104px;
    }
    &.section {
      left: 184px;
    }
    &.particella {
      left: 95px;
    }
  }
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
