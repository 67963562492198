<template>
  <div class="currentSupplierSection">
    <div class="currentSupplierSection__input">
      <div class="LabelM">FORNITORE ATTUALE</div>
      <div @click="changeFocus(true)" :class="inputFocus ? 'inputFocus' : ''">
        <v-autocomplete
          :items="getSupplyList"
          item-text="societa"
          attach="div.v-input__control"
          placeholder="Es: ENI Gas e Luce"
          v-model="supplier"
          @blur="
            v$.supplier.$touch()
            changeFocus(false)
          "
          :error-messages="errorSupplier"
          return-object
          :hide-no-data="true"
          :hide-selected="true"
          @update:search-input="onInputChange"
        ></v-autocomplete>
      </div>
    </div>
    <div class="currentSupplierSection__box">
      <div class="title">Perchè te lo chiediamo</div>
      <h5 class="desc">
        Selezionando il tuo precedente fornitore possiamo indicarti dove trovare
        i dati che ti verranno richiesti per la stima all'interno della
        bolletta.<br />
        Non preoccuparti, useremo i tuoi dati solo per farti avere una stima dei
        costi.
      </h5>
    </div>
    <MYSButton
      width="175"
      buttonClass="primaryButton"
      :disabled="v$.supplier.$invalid"
      @buttonClick="next"
      buttonText="Continua"
    />
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
import { debounce } from 'lodash'

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'newCurrentSupplier',
  components: { MYSButton },
  data() {
    return {
      supplier: null,
      hint: '',
      inputFocus: false
    }
  },
  watch: {
    'v$.supplier.$error': function (val) {
      val
        ? this.setValidationErrors('SUPPLIER_INVALID')
        : this.deleteValidationError('SUPPLIER_INVALID')
    }
  },
  computed: {
    ...mapGetters('upsellingComm', [
      'supplyDetail',
      'commodityType',
      'getSupplyList'
    ]),
    errorSupplier() {
      let errArray = []
      if (this.hint.length >= 1) return []
      if (!this.v$.supplier.$dirty) return errArray
      this.v$.supplier.required.$invalid === true &&
        errArray.push('Seleziona un fornitore dalla lista.')
      return errArray
    }
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'setSupplyDetailRoot',
      'setValidationErrors',
      'deleteValidationError',
      'setSupplyList'
    ]),
    ...mapActions('upsellingComm', ['retrieveSupplyList']),
    changeFocus(value) {
      this.inputFocus = value
    },
    onInputChange(value) {
      this.hint = value ? value : ''
      this.debouncedInputHandler(value)
    },
    debouncedInputHandler: debounce(function (value) {
      this.hint = value ? value : ''
      if (value && value.length >= 3) {
        let contractType = this.commodityType === 'ele' ? 'ELE' : 'GAS'
        this.retrieveSupplyList({ contractType, hint: value })
      }
    }, 500),

    next() {
      this.setSupplyDetailRoot({ oldProvider: this.supplier })
      this.$emit('nextStep', 'upsellingcom_supplydetail_open')
    }
  },
  created() {
    if (this.supplyDetail.oldProvider) {
      this.supplier = this.supplyDetail.oldProvider
    }
  },
  validations: {
    supplier: { required }
  }
}
</script>
<style lang="scss" scoped>
.currentSupplierSection {
  max-width: 652px;
  color: black;
  &__input {
    margin-top: 32px;
    margin-bottom: 18px;
  }
  &__box {
    padding: 24px;
    border-radius: 8px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .v-text-field {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .LabelM {
    color: $neutri-neutral-50;
  }
}
</style>
<style lang="scss">
.currentSupplierSection {
  .error--text {
    .v-messages__message {
      display: flex;
      align-items: center;
      margin-right: 2px;
      margin-top: 6px;
      &::before {
        margin-right: 6px;
        content: url('@/assets/icons/Error.svg');
      }
    }
    input {
      color: red !important;
    }
  }
  .v-input__icon .mdi-menu-down::before {
    content: url('@/assets/icons/search.svg');
    transform: none !important;
  }
  .v-input__icon .mdi-menu-down {
    transform: none !important;
    -webkit-transform: none !important;
  }
  .v-select__selection--comma {
    color: $srg-blue !important;
  }
  .v-menu__content {
    box-shadow: 0 2px 4px 0 rgba(44, 53, 66, 0.08),
      0 4px 8px 0 rgba(44, 53, 66, 0.08), 0 12px 24px 0 rgba(44, 53, 66, 0.06);
    .v-list.v-select-list {
      border-radius: 8px;
      .v-list-item.v-list-item--link {
        border-bottom: 1px solid #eaeef5;
        padding: 5px 24px;
        margin: 0 4px;
        line-height: 1.4;
        letter-spacing: 1px;
        font-size: 16px;
        min-height: initial;
        .itemTypeOwner {
          color: $srg-blue;
        }
        &:hover {
          border: 1px solid $srg-blue !important;
        }
        &:hover::before,
        &--active::before {
          opacity: 0;
        }
        &:hover,
        &--active {
          .itemTypeOwner {
            color: $srg-blue;
          }
        }
        &:last-child {
          border-bottom: none;
        }
        &__title {
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.5px;
        }
      }
      .v-list-item--link:before {
        opacity: 0 !important;
      }
    }
  }
  .v-menu__content::-webkit-scrollbar-thumb {
    background-color: $srg-blue;
    &:hover {
      background-color: $srg-blue;
    }
  }
  .v-menu__content::-webkit-scrollbar {
    background: $neutri-neutral-30;
  }

  .inputFocus .v-input__slot::before {
    border-color: $srg-blue !important;
  }
}
</style>
