<template>
  <v-dialog
    v-model="dialogOpen"
    persistent
    max-width="558"
    max-height="588"
    overlay-opacity="0.6"
    content-class="serviceUnavailableDialog"
  >
    <div class="serviceUnavailableDialog">
      <div v-if="canClose" class="close">
        <img
          src="../..//assets/icons/close.svg"
          @click="dialogOpen = false"
          alt="close"
          width="24"
          height="24"
        />
      </div>
      <div class="body mt-4">
        <img
          v-if="imageResponse === 'ok'"
          src="../../assets/icons/Illo.svg"
          class="mb-8"
        />
        <img
          v-if="imageResponse === 'KO-nonInviata'"
          src="../../assets/icons/IlloNotInvied.svg"
          class="mb-8"
        />
        <img
          v-if="imageResponse === 'KO-500'"
          src="../../assets/icons/IlloError.svg"
          class="mb-8"
        />
        <div class="SubheadingXL mb-2">{{ title }}</div>
        <div class="BodyL">
          <span>
            {{ body }}
          </span>
          <span class="BodyL-Strong">
            {{ bodyStrong }}
          </span>
        </div>
        <div class="containerButton">
          <MYSButton
            v-if="cta1"
            :buttonText="cta1"
            width="251"
            buttonClass="primaryButton mt-8"
            @buttonClick="
              $emit('update:serviceUnavailableDialogOpen', false)
              goHomeOrGoForm()
              retry()
            "
          />
          <MYSButton
            v-if="cta2"
            :buttonText="cta2"
            width="280"
            :buttonClass="
              ctaExternal
                ? 'primaryInvertedButtonBorded GMEButton mt-4'
                : 'primaryInvertedButtonBorded mt-4'
            "
            @buttonClick="
              ctaExternal
                ? toGME()
                : $emit('update:serviceUnavailableDialogOpen', false)
              goFAQ()
              returnToHome()
            "
          />
          <!-- <img v-if="ctaExternal" src="../../assets/icons/arrow-up-dx.svg" /> -->
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import MYSButton from './MYSButton.vue'
export default {
  name: 'ServiceUnavailableDialog',
  components: { MYSButton },
  props: {
    serviceUnavailableDialogOpen: {
      type: Boolean
    },
    imageResponse: {
      type: String,
      default: 'KO-500'
    },
    title: {
      type: String
    },
    body: {
      type: String
    },
    bodyStrong: {
      type: String,
      default: ''
    },
    cta1: {
      type: String
    },
    cta2: {
      type: String
    },
    ctaExternal: {
      type: Boolean,
      default: false
    },
    canClose: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dialogOpen: {
      get() {
        return this.serviceUnavailableDialogOpen
      },
      set(val) {
        this.$emit('update:serviceUnavailableDialogOpen', val)
      }
    }
  },
  methods: {
    goFAQ() {
      this.$emit('goFAQ')
    },
    goHomeOrGoForm() {
      this.$emit('goHomeOrGoForm')
    },
    retry() {
      this.$emit('retry')
    },
    returnToHome() {
      this.$emit('returnToHome')
    },
    toGME() {
      this.$emit('toGME')
    }
  }
}
</script>

<style lang="scss" scoped>
.serviceUnavailableDialog {
  background: white;
  border-radius: 20px !important;
  padding: 40px;
  .close {
    display: flex;
    justify-content: flex-end;
    img {
      cursor: pointer;
    }
  }
  .body {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  .containerButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .toGMEicon {
    width: 24px;
    margin-left: 7px;
  }
}
</style>
