import { axiosACN } from '@/js/axiosInstances.js'
import { ENV_CONFIGS } from '@/js/configs.js'
import sha256 from 'crypto-js/sha256'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    code: '',
    cfpi: '',
    name: '',
    surname: '',
    email: '',
    confirmEmail: '',
    birthDate: '',
    birthPlace: '',
    genre: '',
    mktFromSorgenia: '',
    mktFromTerzi: '',
    mktFromCustom: '',
    legal: '',
    password: ''
  },
  getters: {
    code: (state) => state.code,
    cfpi: (state) => state.cfpi,
    name: (state) => state.name,
    surname: (state) => state.surname,
    email: (state) => state.email,
    confirmEmail: (state) => state.confirmEmail,
    birthDate: (state) => state.birthDate,
    birthPlace: (state) => state.birthPlace,
    genre: (state) => state.genre,
    mktFromSorgenia: (state) => state.mktFromSorgenia,
    mktFromTerzi: (state) => state.mktFromTerzi,
    mktFromCustom: (state) => state.mktFromCustom,
    legal: (state) => state.legal,
    password: (state) => state.password
  },
  mutations: {
    setUserData(state, userData) {
      state.code = userData.code
      state.cfpi = userData.cfpi
      state.name = userData.name
      state.surname = userData.surname
      state.email = userData.email
      state.password = userData.password
      state.phone = userData.phone
    },

    setPrivacy(state, privacyFlags) {
      state.legal = privacyFlags.legal
      state.mktFromSorgenia = privacyFlags.mktFromSorgenia
      state.mktFromTerzi = privacyFlags.mktFromTerzi
      state.mktFromCustom = privacyFlags.mktFromCustom
    },

    resetState(state) {
      state.code = ''
      state.cfpi = ''
      state.name = ''
      state.surname = ''
      state.email = ''
      state.phone = ''
      state.confirmEmail = ''
      state.birthDate = ''
      state.birthPlace = ''
      state.genre = ''
      state.mktFromSorgenia = ''
      state.mktFromTerzi = ''
      state.mktFromCustom = ''
      state.legal = ''
    }
  },
  actions: {
    async register(ctx, params) {
      const shaPw = sha256(ctx.state.password).toString()
      try {
        const payload = {
          pivacf: ctx.state.cfpi,
          codcli: ctx.state.code,
          nome: ctx.state.name,
          cognome: ctx.state.surname,
          username: ctx.state.email,
          consenso: true,
          password: shaPw,
          canaleProvenienza: 'MYS'
        }
        const options = {
          headers: {
            Authorization: ENV_CONFIGS.BASIC_TOKEN
          }
        }

        let res = await axiosACN.post(
          '/sorgenia/V3/selfRegistration',
          payload,
          options
        )

        // Manage business logic errors (coming with status 200 OK + errorcode)
        if (res.data.errorCode) {
          // No bus logic errors expected
          if (res.data.errorCode === '005') {
            throw Error('CC_CFPI_ERROR')
          } else if (res.data.errorCode === '405') {
            throw Error('ALREADY_REGISTERED')
          } else if (res.data.errorCode === '009') {
            throw Error('TO_BE_CONFIRMED')
          } else if (res.data.errorCode === '1168') {
            throw Error('USER_ALREADY_ASSOCIATED')
          } else {
            throw Error('UNKNOWN_ERROR')
          }
        }

        // reset all fields after successul registration
        ctx.commit('resetState')

        return true
      } catch (err) {
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      }
    },

    async registerCustomer(ctx, params) {
      const shaPw = sha256(ctx.state.password).toString()
      try {
        const payload = {
          nome: ctx.state.name,
          cognome: ctx.state.surname,
          email: ctx.state.email,
          telefono: ctx.state.phone,
          idProfilo: '11',
          codiceFiscale: ctx.state.cfpi,
          consensoMarketing: !!ctx.state.mktFromSorgenia,
          consensoSoggettiTerzi: !!ctx.state.mktFromTerzi,
          consensoProfilazione: !!ctx.state.mktFromCustom,
          flagInformativa: !!ctx.state.legal,
          password: shaPw,
          canaleProvenienza: 'MYS'
          // ragioneSociale: '',
          // partitaIva: ''
        }
        const options = {
          headers: {
            Authorization: ENV_CONFIGS.BASIC_TOKEN
          }
        }

        let res = await axiosACN.post(
          '/sorgenia/V3/selfRegistrationProspect',
          payload,
          options
        )

        // Manage business logic errors (coming with status 200 OK + errorcode)
        if (res.data.errorCode) {
          // No bus logic errors expected
          if (res.data.errorCode === '090') {
            throw Error('CC_CFPI_ERROR')
          } else if (res.data.errorCode === '400') {
            throw Error('ALREADY_REGISTERED')
          } else if (res.data.errorCode === '009') {
            throw Error('TO_BE_CONFIRMED')
          } else if (res.data.errorCode === '1168') {
            throw Error('USER_ALREADY_ASSOCIATED')
          } else {
            throw Error('UNKNOWN_ERROR')
          }
        }

        // reset all fields after successul registration
        ctx.commit('resetState')

        return true
      } catch (err) {
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      }
    }
  }
}
