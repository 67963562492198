<template>
  <div class="useGas mt-4">
    <div :class="`useGas__btn ${cook ? 'active' : ''}`" @click="cook = !cook">
      <div class="BodyL">Cottura</div>
    </div>
    <div
      :class="`useGas__btn ${hotWater ? 'active' : ''}`"
      @click="hotWater = !hotWater"
    >
      <div class="BodyL">Acqua calda</div>
    </div>
    <div
      :class="`useGas__btn ${heating ? 'active' : ''}`"
      @click="heating = !heating"
    >
      <div class="BodyL">Riscaldamento</div>
    </div>
    <div class="useGas__box">
      <div class="BodyXL-Strong mb-4">Perché te lo chiediamo</div>
      <div class="BodyL">
        Trovi questa informazione esatta sulla bolletta. Indica per cosa
        utilizzi il gas <br />
        (es. riscaldamento, cottura cibi, acqua calda, ecc.).
      </div>
    </div>
    <MYSButton
      buttonClass="primaryButton"
      width="175"
      :disabled="!cook && !hotWater && !heating"
      @buttonClick="next()"
      buttonText="Continua"
    />
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
export default {
  name: 'newUseGas',
  components: { MYSButton },
  data() {
    return {
      cook: false,
      hotWater: false,
      heating: false
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['commodityType', 'typeRequest', 'address'])
  },
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetail']),
    ...mapActions('analytics', ['trackTapEvent']),
    canAnticipate() {
      let currentDate = new Date()
      let batchDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        10
      ).getTime()
      let afterthoughtDate = batchDate - 14 * 24 * 60 * 60 * 1000
      if (currentDate.getDate() <= 10) {
        return true
      } else if (currentDate.getTime() > afterthoughtDate) {
        return true
      } else {
        return false
      }
    },
    next() {
      this.setSupplyDetail({
        useToCook: this.cook,
        useToHotWater: this.hotWater,
        useToHeating: this.heating
      })
      if (this.cook) {
        this.trackTapEvent({
          name: `upsellingcom_gasUseToCook_tap`,
          params: {
            type: this.commodityType,
            client_selected: this.address
              ? this.address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      }
      if (this.hotWater) {
        this.trackTapEvent({
          name: `upsellingcom_gasUseToHotWater_tap`,
          params: {
            type: this.commodityType,
            client_selected: this.address
              ? this.address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      }
      if (this.useToHeating) {
        this.trackTapEvent({
          name: `upsellingcom_gasUseToHeating_tap`,
          params: {
            type: this.commodityType,
            client_selected: this.address
              ? this.address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      }

      if (this.commodityType === 'gas') {
        if (this.canAnticipate() && this.typeRequest === 'CA') {
          this.$emit('setFlowType', 'canAnticipate')
          this.$emit('goToStep', 18, 'upsellingcom_activationtype_open')
        } else {
          this.$emit('setFlowType', 'canNotAnticipate')
          this.$emit('goToStep', 20, 'notrack')
        }
      } else {
        if (this.canAnticipate() && this.typeRequest === 'CA') {
          this.$emit('goToStep', 20, 'upsellingcom_activationtype_open')
        } else {
          this.$emit('setFlowType', 'canNotAnticipate')
          this.$emit('goToStep', 22, 'notrack')
        }
      }
    }
  },
  created() {
    this.cook = this.$store.getters['upsellingComm/supplyDetail'].gas.useToCook
    this.hotWater =
      this.$store.getters['upsellingComm/supplyDetail'].gas.useToHotWater
    this.heating =
      this.$store.getters['upsellingComm/supplyDetail'].gas.useToHeating
  }
}
</script>
<style lang="scss" scoped>
.useGas {
  max-width: 652px;
  color: black;
  margin-top: 32px;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 56px;
    border-radius: 20px;
    border: solid 1px $neutri-neutral-30;
    margin: 8px 0 16px;
    padding: 0 16px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/plus.svg');
      position: absolute;
      right: 15px;
      top: calc(30%);
    }
    &.active {
      background-color: $neutri-neutral-20;
      &::after {
        content: url('../../../../assets/icons/ic-check-medium.svg');
        position: absolute;
        right: 15px;
        top: calc(30%);
      }
    }
    .BodyL {
      color: $srg-blue;
    }
  }
  &__box {
    padding: 24px;
    border-radius: 8px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
    margin-top: 32px;
  }
}
</style>
