<template>
  <div class="upload">
    <section class="mb-8" v-if="!isCallMeBack">
      <div class="BodyL mb-6">
        Inviaci la tua bolletta, un nostro consulente ti seguirà nella
        sottoscrizione.
      </div>
      <div class="mb-6">
        <div class="upload__box" @click="handleClick">
          <div class="ButtonM">Carica la tua bolletta</div>
          <v-file-input
            id="bill-upload"
            style="display: none"
            accept="image/*"
            ref="files"
            v-model="currFiles"
            multiple
            clearable
            @change="inputChanged"
          />
        </div>
        <div
          v-for="(file, i) in billDocument"
          :key="i"
          class="upload__fileuploaded"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="BodyL upload__fileuploaded__text">{{ file.name }}</div>
            <img
              src="../../../../assets/icons/Trash.svg"
              @click="deleteFile(i)"
            />
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="mb-6">
        Un nostro operatore ti contatterà al più presto per completare il
        processo.
      </div>
    </section>
    <div class="mt-4 mb-4 inputContainer">
      <div class="HeadingsSubtitleM mb-4">Dove ti chiamiamo?</div>
      <div class="LabelM">NUMERO DI TELEFONO</div>
      <v-text-field
        class="pt-0"
        v-model="numberPhone"
        type="number"
        :rules="[rules.required]"
      ></v-text-field>
    </div>
    <div class="mb-8 selectContainer">
      <div class="HeadingsSubtitleM mb-4">Hai preferenze di orario?</div>
      <div class="BodyL mb-4">
        Siamo disponibili tutti i giorni dalle 9 alle 21. Il sabato e la domenca
        dalle 9 alle 18.
      </div>
      <div class="selectInput">
        <div class="LabelM">FASCIA ORARIA</div>
        <v-select
          class="pt-0"
          :items="['Indifferente', 'Mattina', 'Pomeriggio', 'Sera']"
          label=""
          v-model.trim="times"
        ></v-select>
      </div>
    </div>
    <MYSButton
      button-class="primaryButton"
      width="220"
      :loading="loading"
      :disabled="disableBtn"
      @buttonClick="upload"
      button-text="Invia richiesta"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import GenErr from '@/js/genericErrors'
import MYSButton from '../../../../components/global/MYSButton.vue'

export default {
  name: 'newBillUpload',
  props: ['isCallMeBack'],
  components: { MYSButton },
  data() {
    return {
      rules: { required: (value) => !!value || 'Campo obbligatorio.' },
      billDocument: [],
      currFiles: [],
      clientDetail: null,
      numberPhone: '',
      times: 'Indifferente',
      loading: false
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['client', 'commodityType']),
    disableBtn() {
      if (!this.isCallMeBack) {
        return this.billDocument.length === 0 || this.numberPhone === ''
      } else {
        return this.numberPhone === ''
      }
    }
  },
  created() {
    this.getDetailForClient({
      clientOwnerCode: this.client.clientOwnerCode
    }).then((client) => {
      this.clientDetail = client
      this.numberPhone = client.mobilePhone || client.phone
    })
  },
  methods: {
    ...mapActions('interactions', ['upsellingLeadFoto']),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('interactions', { callMeBack: 'callMeBack' }),
    handleClick() {
      this.trackTapEvent({
        name: 'upsellingcom_uploadbill_tap',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.client.clientOwnerCode || ''
        }
      })
      document.getElementById('bill-upload').click()
    },
    deleteFile(i) {
      this.billDocument.splice(i, 1)
      this.currFiles.splice(i, 1)
    },
    inputChanged() {
      let size = this.billDocument.reduce((accumulator, file) => {
        return accumulator + file.size
      }, 0)
      if (size + this.currFiles[0].size < 4000 * 1024) {
        this.billDocument = [...this.currFiles, ...this.billDocument]
      } else {
        this.dialogMaxSize = true
      }
    },
    upload() {
      this.loading = true
      if (!this.isCallMeBack) {
        this.trackTapEvent({
          name: 'upsellingcom_uploadbillconfirm_tap',
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: this.client.clientOwnerCode || ''
          }
        })
        let formData = new FormData()
        let input = {
          upsellingType: 'upselling',
          indirizzo: this.client.clientType,
          comune: this.client.residentialCity,
          cap: this.client.residentialCAP,
          codiceCliente: this.client.clientCode,
          telefono: this.numberPhone,
          nome: this.client.name,
          cognome: this.client.surname,
          ragioneSociale: this.client.businessName,
          piva: this.client.VATNumber,
          email: this.client.contactMail,
          oraPeriodo: this.times.toLowerCase(),
          tipoOfferta: this.commodityType.toUpperCase()
        }

        formData.append('input', JSON.stringify(input))

        for (var i = 0; i < this.billDocument.length; i++) {
          let index = i
          formData.append(
            'files',
            this.billDocument[index],
            this.billDocument[index].name
          )
        }

        this.upsellingLeadFoto(formData)
          .then((resp) => {
            this.loading = false
            if (resp) this.$router.push({ name: 'successUploadComm' })
          })
          .catch((error) => {
            this.loading = false
          })
      } else {
        this.trackTapEvent({
          name: 'upsellingcom_callmebackconfirm_tap',
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: this.client.clientOwnerCode || ''
          }
        })
        let landLineData = {
          clientCode: this.client.clientOwnerCode,
          commodity: this.commodityType === 'ele' ? 'luce' : 'gas',
          phone: this.numberPhone,
          times: this.times.toLowerCase(),
          email: '',
          notes: `${this.commodityType} - Info per Upselling`
        }
        this.callMeBack(landLineData)
          .then((resp) => {
            this.loading = false
            this.$router.push({ name: `requestSent${this.commodityType}` })
          })
          .catch((error) => {
            this.loading = false
            GenErr.handleGenericError(error && error.message)
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.upload {
  max-width: 652px;
  color: black;
  &__box {
    position: relative;
    width: 652px;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 20px;
    border: solid 1px $neutri-neutral-30;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/Upload.svg');
      position: absolute;
      right: 15px;
      top: calc(50% - 12px);
    }
    .ButtonM {
      color: $srg-blue;
    }
  }
  &__fileuploaded {
    position: relative;
    width: 652px;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 20px;
    border: solid 1px $neutri-neutral-30;
    &__text {
      display: flex;
      align-items: center;
      max-width: 90%;
      overflow: hidden;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  &__callNum {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  &__pref {
    font-size: 14px;
    font-weight: 500;
  }
  .availability {
    margin-top: -10px;
  }
  .inputContainer {
    width: 343px;
    .LabelM {
      color: $neutri-neutral-50;
    }
  }
  .selectContainer {
    width: 652px;
    .LabelM {
      color: $neutri-neutral-50;
    }
    .selectInput {
      width: 342px;
    }
  }
}
</style>
