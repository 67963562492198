<template>
  <div class="mt-8">
    <div class="callNow mb-4">
      <div class="BodyL-Strong mb-3">Chiama ora</div>
      <div class="BodyL">
        Contattaci gratuitamente al numero
        <b>{{ formatNumber('fisso') }}</b> (da telefono fisso) o
        <b>{{ formatNumber('mobile') }}</b> (da mobile).
      </div>
    </div>
    <div class="speak__box mb-12" @click="gotoNext(true)">
      <div class="ButtonM">Richiamami</div>
      <img src="../../../../assets/icons/chevron_rx.svg" />
    </div>
  </div>
</template>
<script>
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'newSpeakWithComm',
  data() {
    return {
      landLineData: {},
      mobile: {}
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['commodityType'])
  },
  methods: {
    ...mapActions('interactions', ['getChannelInfo']),
    formatNumber(typeNumber) {
      if (typeNumber === 'fisso') {
        return this.landLineData
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return this.mobile.replace(/^(\d{2})(\d{3})(\d+)$/, '$1 $2 $3')
      }
    },
    gotoNext(val) {
      this.$emit('setCallMeBack', val)
      this.$emit(
        'nextStep',
        val ? 'upsellingcom_callmeback_open' : 'upsellingcom_uploadbill_open'
      )
    }
  },
  created() {
    this.$emit('setCallMeBack', false)
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area: consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
    })
      .then((resp) => {
        this.landLineData = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.MOBILE,
      area: consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
    })
      .then((resp) => {
        this.mobile = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  }
}
</script>
<style lang="scss" scoped>
.speak {
  &__box {
    display: flex;
    justify-content: space-between;
    width: 652px;
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    cursor: pointer;
    color: $srg-blue;
    img {
      width: 24px;
      height: 24px;
    }
    .ButtonM {
      line-height: 24px;
    }
  }
  .question {
    font-size: 20px;
    margin-bottom: 24px;
  }
}
.callNow {
  width: 652px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  background-color: $neutri-neutral-10;
  border-radius: 20px;
  border: solid 1px $neutri-neutral-30;
}
</style>
