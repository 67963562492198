<template>
  <div class="sectionInfoConsent">
    <div class="BodyL mb-2">
      Ti invitiamo a prendere visione dei documenti qui riportati per poter
      proseguire
    </div>
    <div
      v-if="typeRequest === 'CA'"
      @click="setCheckBox('recesso')"
      :class="`checkbox ${recesso ? 'selected' : ''}`"
    >
      <div class="BodyL">
        Dichiarazione di
        <span
          @click.stop="openPdf('recesso')"
          class="BodyL-Underline underline brand-blu"
          >recesso dall’attuale fornitore*</span
        >
      </div>
    </div>

    <div
      @click="setCheckBox('contractCondition')"
      :class="`checkbox ${contractCondition ? 'selected' : ''}`"
    >
      <div class="BodyL">
        Accetto le
        <span
          @click.stop="openPdf('contract-conditions')"
          class="BodyL-Underline underline brand-blu"
          >Condizioni di contratto*</span
        >
        e ho preso visione dell'
        <span
          @click.stop="openPdf('infoPrivacy')"
          class="BodyL-Underline underline brand-blu"
          >Informativa generale clienti*</span
        >
        e
        <span
          @click.stop="openPdf('infoSIC')"
          class="BodyL-Underline underline brand-blu"
          >Informativa SIC*</span
        >
      </div>
    </div>
    <div
      @click="setCheckBox('clauses')"
      :class="`checkbox ${clauses ? 'selected' : ''}`"
    >
      <div class="BodyL">
        Ho preso visione e accetto le
        <span
          @click.stop="openPdf('clauses')"
          class="BodyL-Underline underline brand-blu"
          >Clausole di sottoscrizione specifica*</span
        >
      </div>
    </div>
    <hr class="divider" />
    <div
      @click="setAllcheckBox"
      :class="`BodyL-Strong checkbox d-flex ${accept ? 'selected' : ''}`"
    >
      Ho preso visione di tutti i documenti
    </div>
    <MYSButton
      width="175"
      buttonText="Continua"
      buttonClass="primaryButton mt-5"
      :disabled="!contractCondition || !clauses || !recesso"
      @buttonClick="addInfoConsent"
    />
    <PdfViewer
      :showDialog="showPdf"
      :url="pdfDataUrl"
      @closing-pdfViewer="showPdf = false"
      :attachTo="'body'"
      height="600px"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { getBase64PublicDoc } from '@/js/utils'
import { axiosCMS } from '@/js/axiosInstances.js'
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import { ENV_CONFIGS } from '@/js/configs'
import MYSButton from '@/components/global/MYSButton.vue'

export default {
  name: 'newInfoConsentComm',
  data() {
    return {
      accept: false,
      contractCondition: false,
      clauses: false,
      recesso: false,
      nota: false,
      showPdf: false,
      pdfDataUrl: '',
      pdfDocs: {}
    }
  },
  computed: {
    ...mapGetters('upsellingComm', [
      'consens',
      'client',
      'exportedQuote',
      'typeRequest',
      'commodityType',
      'getInfoUpselling'
    ]),
    catalogList() {
      return this.getInfoUpselling.catalogList[0]
    }
  },
  async mounted() {
    this.getDocsByCategory('info-consensi').then((docs) => {
      this.pdfDocs.recesso = docs.find(
        (item) => item.id === 'dichiarazione-recesso-fornitore'
      )
      this.pdfDocs.infoPrivacy = docs.find(
        (item) => item.id === 'informativa-privacy'
      )
    })
    this.pdfDataUrl = await this.getDocumentCommodity(this.catalogList.docName)
    this.getDocUpsellingUnfairTerms().then(({ data }) => {
      this.pdfDocs.clauses = data.commodity
    })

    if (this.consens) {
      this.setAllcheckBox()
    }
    this.trackTapEvent({
      name: 'contract_conditions_viewed',
      params: {
        services_to_subscribe: this.commodityType,
        funnel_segment: this.client?.clientType?.toLowerCase(),
        flow: 'subscription'
      }
    })
  },
  methods: {
    ...mapActions('documents', [
      'getDocsByCategory',
      'getDocUpsellingUnfairTerms'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('offers', ['getDocumentCommodity']),
    setCheckBox(checkbox) {
      this.$data[checkbox] = !this.$data[checkbox]
      if (this.contractCondition && this.clauses && this.recesso) {
        this.accept = true
        this.$store.commit('upsellingComm/setConsens', true)
      } else {
        this.accept = false
        this.$store.commit('upsellingComm/setConsens', false)
      }
      if (this.contractCondition && this.clauses) {
        this.trackTapEvent({
          name: 'upsellingcom_consent_checked',
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: this.client.clientOwnerCode || ''
          }
        })
      }
    },
    setAllcheckBox() {
      this.accept = !this.accept
      if (this.accept) {
        this.contractCondition = true
        this.clauses = true
        this.recesso = true
        this.nota = true
        this.$store.commit('upsellingComm/setConsens', true)
        this.trackTapEvent({
          name: 'upsellingcom_consent_checked',
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: this.client.clientOwnerCode || ''
          }
        })
      } else {
        this.contractCondition = false
        this.clauses = false
        this.recesso = false
        this.nota = false
        this.$store.commit('upsellingComm/setConsens', false)
      }
    },
    openPdf(doc) {
      let pdfUrl = ''
      switch (doc) {
        case 'contract-conditions':
          this.showPdf = true
          this.trackTapEvent({
            name: 'upsellingcom_getpdfcontractconditions',
            params: {
              type: this.$store.getters['upsellingComm/commodityType'],
              client_selected: this.client.clientOwnerCode || ''
            }
          })
          break
        case 'clauses':
          window.open(this.pdfDocs.clauses)
          this.trackTapEvent({
            name: 'upsellingcom_getpdfclauses',
            params: {
              type: this.$store.getters['upsellingComm/commodityType'],
              client_selected: this.client.clientOwnerCode || ''
            }
          })
          break
        case 'recesso':
          pdfUrl = axiosCMS.defaults.baseURL + this.pdfDocs.recesso.document
          getBase64PublicDoc(pdfUrl).then((base64Data) => {
            this.pdfDataUrl = base64Data
            this.showPdf = true
          })
          this.trackTapEvent({
            name: 'upsellingcom_getpdfclauses',
            params: {
              type: this.$store.getters['upsellingComm/commodityType'],
              client_selected: this.client.clientOwnerCode || ''
            }
          })
          break
        case 'infoPrivacy':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.generalClientsInformative)
          // pdfUrl = axiosCMS.defaults.baseURL + this.pdfDocs.infoPrivacy.document
          // getBase64PublicDoc(pdfUrl).then((base64Data) => {
          //   this.pdfDataUrl = base64Data
          //   this.showPdf = true
          // })
          this.trackTapEvent({
            name: 'upsellingv2_getpdfinfoPrivacy',
            params: {
              type: this.$store.getters['upsellingComm/commodityType'],
              client_selected: this.client.clientOwnerCode || ''
            }
          })
          break
        case 'infoSIC':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.informativaSIC)
          // this.trackTapEvent({
          //   name: 'upsellingv2_getpdfinfoPrivacy',
          //   params: {
          //     type: this.$store.getters['upsellingComm/commodityType'],
          //     client_selected: this.client.clientOwnerCode || ''
          //   }
          // })
          break
      }
    },
    addInfoConsent() {
      this.$emit('nextStep', 'upsellingcom_verifydata_open')
    }
  },
  components: {
    PdfViewer,
    MYSButton
  }
}
</script>
<style lang="scss" scoped>
.sectionInfoConsent {
  max-width: 652px;
  color: black;

  &__info {
    font-weight: 500;
  }

  .divider {
    margin: 8px 0;
    color: $neutri-neutral-20;
  }

  .checkbox {
    margin: 16px 0;
    display: flex;
    align-items: center;
    color: black;
    cursor: pointer;

    .underline {
      text-decoration: underline;
    }
    .brand-blu {
      color: $brand-blue;
    }

    &.selected {
      &:before {
        margin-right: 16px;
        content: url('../../../../assets/icons/checkbox_active.svg');
      }
    }

    &:before {
      margin-right: 16px;
      content: url('../../../../assets/icons/checkbox.svg');
    }

    &.allConsent {
      border-top: 1px solid #ccd0e1;
      height: 100px;
      font-weight: 500;
      margin-bottom: 32px;
    }
  }
}
</style>
