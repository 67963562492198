<template>
  <div class="activation">
    <h5 class="HeadingL mb-6">Hai bisogno di anticipare l’attivazione?</h5>
    <div class="activation__btn" @click="next(false)">
      <div class="LabelM">ATTIVAZIONE STANDARD</div>
      <div class="BodyL">
        Utenza attiva dal {{ afterthought }} senza interruzioni
      </div>
    </div>
    <div class="activation__btn" @click="next(true)">
      <div class="LabelM">ATTIVAZIONE ANTICIPATA</div>
      <div class="BodyL">
        Utenza attiva dal {{ noAfterthought }} senza interruzioni, avvio lavori
        pre ripensamento
      </div>
    </div>
    <div class="activation__box">
      <div class="BodyXL-Strong mb-4">Che cosa è l’attivazione anticipata?</div>
      <div class="BodyL-Strong mb-4">Diritto di ripensamento</div>
      <div class="BodyL mb-4">
        Quando sottoscrivi un contratto fuori dai locali commerciali del
        soggetto dal quale acquisti un bene o un servizio, hai 14 giorni di
        tempo per cambiare idea. Le ragioni possono essere diverse, e non serve
        dare motivazione.
      </div>
      <div class="BodyL mb-4">
        Se ti avvali del diritto di ripensamento, il recesso dal contratto è
        valido a prescindere dal motivo che c’è dietro, senza costi.
      </div>
      <div class="BodyL">
        Se scegli di anticipare l’attivazione e di rinunciare al diritto di
        ripensamento, potrai in ogni caso annullare l’attivazione, ma ti
        verranno addebitati, qualora ci fossero, i costi dell’attivazione fino a
        quel momento.
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'newActivationTimes',
  data() {
    return {
      afterthought: '',
      noAfterthought: ''
    }
  },
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetailRoot']),
    ...mapActions('analytics', ['trackTapEvent']),
    getActivationDate() {
      const computeMonthScad = (refDate, yearScad = 0) => {
        let month = null
        let year = yearScad

        if (refDate.getDate() <= 10) {
          month = refDate.getMonth() + 1
        } else {
          month = refDate.getMonth() + 2
        }
        if (month > 11) {
          year = yearScad + 1
          month = month - 11 - 1
        }
        let retDate = new Date(year, month, 1)
        return (
          '01.' +
          (retDate.getMonth() + 1 < 10
            ? '0' + (retDate.getMonth() + 1)
            : retDate.getMonth() + 1) +
          '.' +
          year
        )
      }
      let now = new Date()
      // let monthDate = new Date(now.getFullYear(), now.getMonth(), 1)
      this.noAfterthought = computeMonthScad(now, now.getFullYear())
      this.afterthought = computeMonthScad(
        new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000),
        now.getFullYear()
      )
    },
    next(type) {
      this.setSupplyDetailRoot({
        anticipateActivation: type,
        dac: type ? this.noAfterthought : this.afterthought
      })
      this.$emit(
        'goToStep',
        this.$store.getters['upsellingComm/commodityType'] === 'ele' ? 22 : 20,
        'upsellingcom_verifydata_open'
      )
      let name = type ? 'anticipa' : 'standard'
      this.trackTapEvent({
        name: `upsellingcom_${name}_tap`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  },
  created() {
    this.getActivationDate()
  }
}
</script>
<style lang="scss" scoped>
.activation {
  max-width: 652px;
  color: black;
  &__box {
    padding: 24px;
    border-radius: 8px;
    background-color: $neutri-neutral-10;
    margin-bottom: 40px;
    margin-top: 8px;
  }
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    min-height: 75px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 16px;
    position: relative;
    gap: 4px;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 15px;
      top: calc(50% - 12px);
    }
    .BodyL {
      width: 570px;
    }
  }
}
</style>
